import { ValidationToNotify } from '@core/model/application-api/layer.model';
import { ButtonType } from './edition-validation.model';

export type NotificationValidationConfig = {
  [state in ButtonType]: boolean;
};

type NotificationValidation = {
  [widget in ValidationToNotify]: NotificationValidationConfig;
};

export const NotificationValidations: NotificationValidation = {
  NONE: {
    REJECT: false,
    VALID: false,
  },
  VV_TRAP: {
    REJECT: false,
    VALID: true,
  },
  CNC_EDITION: {
    REJECT: true,
    VALID: true,
  },
  IS_PARCEL: {
    REJECT: false,
    VALID: true,
  },
};

export interface CNCNotification {
  parcelIds: string[];
  email: string;
  valid: boolean;
  content: string[];
}

export interface ISNotification {
  parcelId: string;
  userModifEmail: string;
}
