export enum WidgetType {
  // Gestionnaire de couches
  LAYERS_MANAGER = 'layers-manager',
  // Echelle
  SCALE = 'scale',
  // Zoom sur coordinates
  COORDINATES_ZOOM = 'coordinates-zoom',
  // Coordonnées
  COORDINATES = 'mouse-coordinates',
  // Recherche de lieux
  SEARCH = 'toponym-search',
  // Timeline
  TIMELINE = 'timeline',
  // Légende
  LEGEND = 'legend',
  // Mesure de distance
  MEASURE_LENGTH = 'measure-length',
  // Mesures de surfaces
  MEASURE_AREA = 'measure-area',
  // Calendrier
  DATEPICKER = 'date-picker',
  // VigieVirose
  VIGIEVIROSE = 'syngenta-vigie-virose',
  // VigieVirose
  VIGIEVIROSE_ADMIN = 'syngenta-vigie-virose-admin',
  // Quali'H2O
  QUALIH2O = 'syngenta-qualih2o',
  // Icare
  ICARE = 'syngenta-icare',
  // Icare
  ICARE_ADMIN = 'syngenta-icare-admin',
  // Visiovol
  VISIOVOL = 'syngenta-visiovol',
  // CNC
  QUALICIBLE_CNC = 'syngenta-qc-cnc',
  // OptiSemis
  OPTI_SEMIS = 'syngenta-opti-semis',
  // InterraScan
  INTERRA_SCAN = 'syngenta-interra-scan',
}

export interface WidgetModel {
  widgetId: WidgetType;
  visible: boolean;
  parameters: WidgetParameter[];
  label?: string;
  configuration?: { isVisible: boolean };
}

export interface WidgetParameter {
  id: ParameterId;
  value: string | number | boolean | LayerParameter[] | string[];
}

export interface LayerParameter {
  value: string;
  label: string;
}

export enum ParameterId {
  // Projection
  DISPLAY_PROJ = 'displayProj',
  // Format
  FORMAT = 'format',
  // Nombres de chiffres significatifs
  DIGIT_NUMBER = 'nombrechiffres',
  // Nombre de résultats
  RESULT_COUNT = 'result-count',
  // Nom des couches liées à la timeline
  LAYERNAME = 'layerName',
  // Durée de chaque étape(en ms)
  STEP_DURATION = 'step_duration',
  // Mesures de surfaces
  PERIODICITY = 'periodicite',
  // Date de début
  START_DATE = 'date_depart',
  // Date de fin
  END_DATE = 'date_fin',
  // Graduation
  GRADUATION = 'graduation',
  // Zoom
  ZOOM = 'zoom',
}
