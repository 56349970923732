import { ApplicationModel } from './application-api/application.model';
import { Layer, LayerGroup } from './application-api/layer.model';
import { ParameterId, WidgetModel, WidgetParameter, WidgetType } from './application-api/widget.model';

export interface SpecificationConfig {
  layerCode?: string;
  attributeName?: string;
  labelPopup?: string;
  labelButtonHeader?: string;
  hideLayers: boolean;
  restrictToKnown: boolean;
}

export interface Application extends ApplicationModel {
  parsedConfig: ApplicationConfig;
}

export class ApplicationConfig {
  public geoserverBaseUrl?: string;
  public geoserverMagOsmUrl?: string;
  public initLocation: [number, number];
  public initZoom: number;
  public projection: string;
  public specification?: SpecificationConfig;
  public widgets: WidgetModel[];
  public layers: (LayerGroup | Layer)[];
  public originalConfig: string;

  constructor(config: string) {
    this.originalConfig = config;
    const parsedConfig = JSON.parse(config);
    if (parsedConfig.PARAMS?.length) {
      const geoserverConfig = parsedConfig.PARAMS[0];
      this.geoserverBaseUrl = geoserverConfig.geoserver_baseurl;
      this.geoserverMagOsmUrl = geoserverConfig.geoserver_magOSM_url;
    }
    this.initLocation = parsedConfig.INIT_LOCATION;
    this.initZoom = parsedConfig.INIT_ZOOM;
    this.projection = parsedConfig.PROJECTION_CODE;
    this.specification = parsedConfig.specification;
    this.widgets = parsedConfig.widgetData.map(
      (widget: { widgetId: WidgetType; configuration: { isVisible: boolean }; parameters: object[] }) => ({
        widgetId: widget.widgetId,
        visible: widget.configuration.isVisible,
        parameters: widget.parameters,
        label: this.getWidgetLabel(widget.widgetId),
      })
    );
    if (!this.widgets.find((widget) => widget.widgetId === WidgetType.COORDINATES_ZOOM)) {
      const zoomParameter: WidgetParameter = {
        id: ParameterId.ZOOM,
        value: 15,
      };
      this.widgets.unshift({
        widgetId: WidgetType.COORDINATES_ZOOM,
        visible: false,
        parameters: [zoomParameter],
        label: this.getWidgetLabel(WidgetType.COORDINATES_ZOOM),
      });
    }
    if (!this.widgets.find((widget) => widget.widgetId === WidgetType.LAYERS_MANAGER)) {
      this.widgets.unshift({
        widgetId: WidgetType.LAYERS_MANAGER,
        visible: true,
        parameters: [],
        label: this.getWidgetLabel(WidgetType.LAYERS_MANAGER),
      });
    }
    const timelineWidget = this.widgets.find((widget) => widget.widgetId === WidgetType.TIMELINE);
    if (timelineWidget && !timelineWidget.parameters.find((parameter) => parameter.id === ParameterId.GRADUATION)) {
      const graduationWidget: WidgetParameter = {
        id: ParameterId.GRADUATION,
        value: true,
      };
      timelineWidget.parameters.push(graduationWidget);
    }
    this.layers = parsedConfig.LAYERS;
  }

  toApiConfig(): string {
    const originalConfig = JSON.parse(this.originalConfig);
    const apiConfig = {
      INIT_LOCATION: this.initLocation,
      INIT_ZOOM: this.initZoom,
      PROJECTION_CODE: this.projection,
      PARAMS: originalConfig.PARAMS,
      LAYERS: this.layers,
      widgetData: this.widgets.map((widget) => ({
        widgetId: widget.widgetId,
        configuration: { isVisible: widget.visible },
        parameters: widget.parameters,
      })),
      specification: this.specification,
    };
    if (apiConfig.PARAMS?.length) {
      apiConfig.PARAMS[0].geoserver_baseurl = this.geoserverBaseUrl;
      apiConfig.PARAMS[0].geoserver_magOSM_url = this.geoserverMagOsmUrl;
    }
    const fullConfig = { ...originalConfig, ...apiConfig };
    return JSON.stringify(fullConfig);
  }

  hasWidget(widgetId: WidgetType): boolean {
    return this.widgets.some((widget) => widget.widgetId === widgetId);
  }

  private getWidgetLabel(widgetId: WidgetType): string {
    switch (widgetId) {
      case WidgetType.LAYERS_MANAGER:
        return $localize`:Widget Label:Gestionnaire de couches`;
      case WidgetType.SCALE:
        return $localize`:Widget Label:Échelle`;
      case WidgetType.COORDINATES:
        return $localize`:Widget Label:Coordonnées`;
      case WidgetType.SEARCH:
        return $localize`:Widget Label:Recherche de lieux`;
      case WidgetType.TIMELINE:
        return $localize`:Widget Label:Timeline`;
      case WidgetType.LEGEND:
        return $localize`:Widget Label:Légende`;
      case WidgetType.MEASURE_LENGTH:
        return $localize`:Widget Label:Mesure de distance`;
      case WidgetType.MEASURE_AREA:
        return $localize`:Widget Label:Mesure de surface`;
      case WidgetType.DATEPICKER:
        return $localize`:Widget Label:Calendrier`;
      case WidgetType.VIGIEVIROSE:
        return $localize`:Widget Label:VigieVirose`;
      case WidgetType.VIGIEVIROSE_ADMIN:
        return $localize`:Widget Label:VigieVirose Administration`;
      case WidgetType.QUALIH2O:
        return $localize`:Widget Label:Quali'H2O`;
      case WidgetType.ICARE:
        return $localize`:Widget Label:Service Eudémis`;
      case WidgetType.ICARE_ADMIN:
        return $localize`:Widget Label:Service Eudémis Administration`;
      case WidgetType.OPTI_SEMIS:
        return $localize`:Widget Label:OptiSemis`;
      case WidgetType.INTERRA_SCAN:
        return $localize`:Widget Label:InterraScan`;
      case WidgetType.COORDINATES_ZOOM:
        return $localize`:Widget Label:Zoom sur coordonnées`;
      default:
        return '';
    }
  }
}
